import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Dropzone, FileMosaic } from '@files-ui/react';

export default function ImageDropzone({formData, setFormData, touched, setTouched, errors, uploadProgress}) {

    const updateFiles = (newFile) => {
        setFormData({ ...formData, 'image': newFile });

        if(newFile.length === 0) {
            setTouched({ ...touched, 'image': true });
            return;
        }
        
        setTouched({ ...touched, 'image': false });
    };

    // Function that handles updating the upload status of each file
    const getUploadProgress = (id) => {
        if ( uploadProgress[id] === 100 ) {
            return 'success';
        } else if( uploadProgress[id] ) {
            return 'uploading';
        }
    }

    return (
        <Grid container item justifyContent="center" style={{paddingTop: "15px"}}>
            <Grid item xs={10}>
                <Typography variant="body1" sx={{
                    m: 1, 
                    fontSize: "14pt",
                    paddingLeft: {
                        md: "100px"
                    }
                }}>
                    <strong>Step 2:</strong> Add a pic to display as the show image.
                </Typography>
            </Grid>

            {/* Dropzone */}
            <Grid item xs={8}>
                <Dropzone
                    onChange={updateFiles}
                    value={formData.image}
                    accept="image/*"
                    color='white'
                    background='#424242'
                    label='Drop the show image here'
                    footer={false}
                    autoClean
                    maxFiles={1}
                >
                    {
                        formData.image.map((file) => (
                            <FileMosaic 
                                key={file.id} 
                                {...file} 
                                onDelete={() => setFiles(formData.image.filter((x) => x.id !== file.id))} 
                                info 
                                preview
                                style={{color: "white"}}
                                alwaysActive={false}
                                smartImgFit="center"
                                uploadStatus={getUploadProgress(file.id)}
                                progress={uploadProgress[file.id] || 0}
                            />
                        ))
                    }
                </Dropzone>
            </Grid>
            <Grid item xs={8} sx={{pl: 3, pt: 1}}>
                {
                    !touched.image ? null :
                    <Typography variant='subtitle2' sx={{color: '#d2302f'}}>
                        {errors.image}
                    </Typography>
                }
            </Grid>
        </Grid>
    )
}