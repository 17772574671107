import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Typography, Grid, Paper } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ShowCard from "./ShowCard";
import ShowDetails from "./ShowDetails";
import { setupFirestoreEmulator } from "./utils";

// Sets up the firestore database
setupFirestoreEmulator(db);

export default function Shows() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();
    const [artist, setArtist] = React.useState(null);
    const [shows, setShows] = React.useState([]);
    const [selectedShow, setSelectedShow] = React.useState(null);

    // Queries Firestore for the all of the shows data
    const getShowsInfo = React.useCallback(async () => {
        try {
            // Loop over each returned show doc
            (await getDocs(collection(db, "shows"))).forEach(show => {
                setShows(dat => [...dat, show.data()].sort((a, b) => a.date - b.date));
            })            
        } catch (error) {
            console.error("Error fetching artist data:", error);
        }
    }, []);

    // Hook used to fire the getShowsInfo function
    React.useEffect(() => {
        if (!shows.length) getShowsInfo();
    }, [shows, getShowsInfo]);

    // Hook to handle redirecting signed or unauthenticated users
    React.useEffect(() => {
        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);

    // Hook Hack to intercept the back button
    React.useEffect(() => {
        const handlePopState = (event) => {
            // Prevent the default action of the back button
            window.history.pushState(null, document.title, window.location.href);
            console.log("Back button was pressed, but we ignored it.");
            setSelectedShow(null);
        };

        // Push a new state to prevent going back initially
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    return(
        <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p:{xs: 0, sm: 2}}}>
                    {
                        !selectedShow ?
                            <Grid container>
                                <Typography variant="h4" sx={{pt:{xs:2, sm: 1},  pl:{xs:2, sm: 1}}}>
                                    { artist } Shows
                                </Typography>
                                <Grid container justifyContent="space-evenly" sx={{pb: 1}}>
                                    {
                                        shows && shows.map((show, idx) => {
                                            return (
                                                <Grid item key={idx}>
                                                    <ShowCard show={show} setSelectedShow={setSelectedShow}/>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        :
                            <ShowDetails data={selectedShow} setSelectedShow={setSelectedShow} />
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}
