import React from 'react';
import { Card, Typography, Grid, Box, Skeleton } from '@mui/material';
import { storage } from '../../firebase';
import { timestampToDate2, getDownloadURL, setupStorageEmulator } from './utils';
import { ref, listAll } from 'firebase/storage';

setupStorageEmulator(storage);

export default function ShowCard({ show, setSelectedShow }) {
    const [imageUrl, setImageUrl] = React.useState('');

    // Hook used to load the image from cloud storage
    // on page load
    React.useEffect(() => {
        const fetchImageUrl = async () => {
            
            function isImageFile(filename) {
                const pattern = /\.(jpeg|jpg|png|gif|bmp|tiff|tif|webp)$/i; // Corrected for JavaScript
                return pattern.test(filename);
            }
            
            const listRef = ref(storage, `shows/${show.showPath}`);
            
            listAll(listRef)
            .then(res => {
                let imageFilename = '';
                res.items.forEach(item => {
                    if( isImageFile(item.name) ) imageFilename = item.name;
                });
                return imageFilename;
            })
            .then(async (imgFile) => {
                const url = await getDownloadURL(storage, `shows/${show.showPath}/${imgFile}`);
                setImageUrl(url);
            });
        };
    
        // Only fetch the image URL if it hasn't been set yet. 
        fetchImageUrl();
    }, [show.showPath]);
    
    return (
        <Card 
            sx={{
                m: 2, 
                bgcolor: '#3a3f43',
                '&:hover': {
                    bgcolor: '#585858'
                }}} 
                onClick={() => setSelectedShow({show, imageUrl})
            }
        >
            <Grid container direction="column">
                <Grid item>
                    {
                        imageUrl === '' ? <Skeleton variant="rectangular" sx={{
                            maxWidth: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            width: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            height: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            m: 2
                        }}/> :
                            <Box 
                                component="img"
                                src={imageUrl}
                                alt='venue'
                                sx={{
                                    minHeight: {
                                        xs: "115px",
                                        sm: "175px",
                                        md: "230px"
                                    },
                                    maxWidth: {
                                        xs: "115px",
                                        sm: "175px",
                                        md: "225px"
                                    },
                                    m: 2
                                }}
                            />
                    }
                </Grid>
                <Grid container item justifyContent="center" direction="column" alignContent="center" sx={{
                    mb: 2,
                    alignItems: {
                        xs: "center",
                        md: "flex-start"
                    }
                }}>
                    <Typography variant='h6'>{show.venue}</Typography>
                    <Typography variant='subtitle1'>{`${show.city}, ${show.state}`}</Typography>
                    <Typography variant='body1'><strong>{timestampToDate2(show.date)}</strong></Typography>
                </Grid>
            </Grid>
        </Card>
    )
}