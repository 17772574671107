import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Grid, Paper, Typography } from "@mui/material";

export default function Dashboard() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();

    // console.log("User : ", userCtx)

    React.useEffect(() => {
        // console.log("User : ", userCtx.user)

        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);

    return(
        <Grid container sx={{pt: 3}} justifyContent="center">
            <Grid item xs={11} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p: {xs: 2, sm: 3}, color: "white"}}>
                    <Grid container>
                        <Typography gutterBottom variant="h6">
                            Dashboard
                        </Typography>
                        <Typography variant="body1">
                            Welcome to The Bomb Range, a dedicated space for preserving everything related to Skydog 
                            Gypsy. Explore our collection of archived shows, photographs, and videos. Enjoy reminiscing 
                            about the days of &apos;getting on the bus&apos; with us.
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    ); 
}