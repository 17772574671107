import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Grid, Paper, Typography } from "@mui/material";

export default function SignIn() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        // console.log("User : ", userCtx.user);

        if ( userCtx.user ) {
            if ( userCtx.user.approved ) navigate("/");
        }
    }, [userCtx.user, navigate])

    return(
        !userCtx.user ? null :
            userCtx.user.approved ? null :
                <Grid container style={{paddingTop: "30px"}} justifyContent="center">
                    <Grid item xs={8}>
                        <Paper square={false} variant="outlined" sx={{p: 3, opacity: "0.8"}}>
                            <Typography variant="h6">
                                Welcome to The Bomb Range! We are currently processing your account. Access 
                                to the site will be granted once your account has been approved. Please keep 
                                an eye on your email for the approval notification. Thank you for your patience!
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
    )
}
