import React from 'react';
import { Grid, Paper, Typography, TextField, Button } from "@mui/material";
import { setupFunctionsEmulator } from "../shows/utils";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

setupFunctionsEmulator(functions);

export default function EmailTest({ setMessage, setSeverity, setSnackbarOpen }) {
    const [emailAddress, setEmailAddress] = React.useState('');
  
    const sendEmail = async (address, emailType) => {
        const sendMail = httpsCallable(functions, "genericEmail");
        const mail = await sendMail({email: address, type: emailType});
        setMessage(`'${emailType.charAt(0).toUpperCase() + emailType.slice(1)}' email successfully sent to '${address}'`);
        setSeverity("success");
        setEmailAddress('');

        if ( !mail.data.success ) {
            setMessage(`Failed to send '${emailType}' email to '${address}'`);
            setSeverity("error");
        }

        setSnackbarOpen(true);
    }
  
    return (
        <Paper square={false} variant="outlined" sx={{ display: "flex", flexGrow: 1, bgcolor: "#3a3f43", p: 3}}>    
            <Grid container>
                <Grid item>
                    <Typography gutterBottom variant="h6">
                        Email
                    </Typography>
                </Grid>
                <Grid container item alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item>
                        <Typography gutterBottom variant="body1" sx={{mt: 1}}>
                            Email address:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField 
                            label="Email Address" 
                            variant="outlined" 
                            value={emailAddress}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            sx={{
                                input: { color: "white"},
                                width: "300px",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'gray' }, // Change border color
                                    '&:hover fieldset': { borderColor: 'blue' }, // Change border color on hover
                                    '&.Mui-focused fieldset': { borderColor: 'white' },
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            onClick={() => sendEmail(emailAddress, "welcome")}
                            sx={{backgroundColor: "#006edc"}}
                        >
                            Send Welcome Email
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            onClick={() => sendEmail(emailAddress, "approved")}
                            sx={{backgroundColor: "#01DF3A"}}
                        >
                            Send Approval Email
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            onClick={() => sendEmail(emailAddress, "denied")}
                            sx={{backgroundColor: "#FF4000"}}
                        >
                            Send Denied Email
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}