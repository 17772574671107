import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import NavBar from "./components/navbar/NavBar";
import { Container } from "@mui/material";
import SignIn from "./components/signin/SignIn";
import Dashboard from "./components/dashboard/Dashboard";
import Shows from "./components/shows/Shows";
import Videos from "./components/videos/Videos";
import Pictures from "./components/pictures/Pictures";
import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./components/dashboard/PrivacyPolicy";
import Admin from "./components/admin/Admin";
import NewShow from "./components/shows/new/NewShow";
import NewPictures from "./components/pictures/new/NewPictures";
import NewVideos from "./components/videos/new/NewVideos";

// Redirects any unknown routes/paths to the signin page
const NotFoundRedirect = () => {
    let navigate = useNavigate();
    React.useEffect(() => {
        navigate('/');
    }, [navigate]);
  
    return null; // Or render something until redirected
};

export default function App() {
    return (
        <BrowserRouter>
            <Container maxWidth={false} disableGutters>
                <NavBar/>
                <Routes>
                    <Route path="*" Component={NotFoundRedirect} />
                    <Route path="/" Component={Dashboard} />
                    <Route path="/signin" Component={SignIn} />
                    <Route path="/shows" Component={Shows} />
                    <Route path="/videos" Component={Videos} />
                    <Route path="/pictures" Component={Pictures} />
                    <Route path="/admin" Component={Admin} />
                    <Route path="/newShow" Component={NewShow} />
                    <Route path="/newVideo" Component={NewVideos} />
                    <Route path="/newPicture" Component={NewPictures} />
                    <Route path="/privacy" Component={PrivacyPolicy} />
                </Routes>
                <Footer />
            </Container>
        </BrowserRouter>
    );
}
