import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./contexts/UserContext";
import { ThemeProvider } from '@mui/material';
import theme from './Theme';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
        <UserContextProvider>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </UserContextProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
