import React from 'react';
import { Grid, Typography, TextField, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const usStates = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY"
};

export default function ShowInfo({formData, setFormData, touched, setTouched, errors}) {
    // Update form state
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Mark as touched to show errors
    const handleBlur = (event) => {
        const { name } = event.target;
        setTouched({ ...touched, [name]: true });
    };

    // Handle and changes when the state field updates
    const handleStateChange = (state, val) => {
        setFormData({ ...formData, [state]: val.code });
    }

    // Handle any changes with the date file updates
    const handleDateChange = (state, date) => {
        const formatedDate = new Date(date).toLocaleDateString().replace(/\//g, "-")
        setFormData({ ...formData, [state]: formatedDate });
    }
    
    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} sx={{pb: 1}}>
                <Typography variant="body1" sx={{
                    m: 1, 
                    fontSize: "14pt",
                    paddingLeft: {
                        md: "100px"
                    }
                }}>
                    <strong>Step 1:</strong> Fill out the show information.
                </Typography>
            </Grid>
            <Grid item xs={11} sm={8} md={6} xl={4}>
                
                {/* Venue */}
                <TextField
                    required
                    fullWidth
                    name="venue"
                    label="Venue"
                    variant="outlined"
                    value={formData.venue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.venue && Boolean(errors.venue)}
                    helperText={touched.venue && errors.venue}
                />

                {/* City */}
                <TextField
                    required
                    fullWidth
                    name="city"
                    label="City"
                    variant="outlined"
                    value={formData.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                />

                {/* State */}
                <Autocomplete
                    options={Object.entries(usStates).map(([name, abbr]) => ({ label: name, code: abbr }))}
                    getOptionLabel={(option) => option.label}
                    disableClearable
                    renderInput={(params) => (
                        <TextField 
                            required {...params} 
                            label="Select a state" 
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    onChange={(evt, val) => handleStateChange("state", val)}
                    onBlur={handleBlur}
                    sx={{
                        '& .MuiAutocomplete-popupIndicator': {
                            color: "white"
                        },
                        '& .MuiAutocomplete-paper': {
                            backgroundColor: "green"
                        }
                    }}
                />

                {/* Date */}
                <Grid container item justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Choose the show date"
                            onChange={(newValue) => handleDateChange("date", newValue)}
                            format='MM-DD-YYYY'
                            slotProps={{
                                layout: {
                                    sx: {
                                        color: 'white',
                                        borderRadius: '18px',
                                        borderWidth: '1px',
                                        borderColor: '#848484',
                                        border: '1px solid',
                                        backgroundColor: '#424242'  
                                    }
                                },
                                openPickerIcon: {
                                    sx: {
                                        color: "white"
                                    }
                                },
                                day: {
                                    sx: {
                                        color: "white"
                                    }
                                },
                                leftArrowIcon: {
                                    sx: {
                                        color: "white"
                                    }
                                },
                                rightArrowIcon: {
                                    sx: {
                                        color: "white"
                                    }
                                },
                                switchViewIcon: {
                                    sx: {
                                        color: "white"
                                    }
                                },
                                textField: {
                                    error: touched.date && Boolean(errors.date),
                                    helperText: touched.date && errors.date,
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

            </Grid>
        </Grid>
    )
}
