import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyCtoLUCnS9aJ5pUXB7OnWC0PEm4smRZmbQ",
    authDomain: "bombrange.com",
    databaseURL: "https://bombrange-com.firebaseio.com",
    projectId: "bombrange-com",
    storageBucket: "bombrange-com.appspot.com",
    messagingSenderId: "202791990646",
    appId: "1:202791990646:web:2082edead36fa5df38f657",
    measurementId: "G-5BBRQF3P3V"
};

// Initialize the Firebase Applications
export const firebase = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(firebase, {
    provider: new ReCaptchaEnterpriseProvider("6Le67FApAAAAAF_YVo8I5ElrTnGZxUMDN21YyxGd"),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

// Init Google Analytics
const analytics = getAnalytics(firebase);

// Initialize the Firebase Authentication
export const auth = getAuth(firebase);

// Initialize the Firebase Firestore db
export const db = getFirestore(firebase);

// Initialize Cloud Storage
export const storage = getStorage(firebase);

// Instanciate Cloud Functions
export const functions = getFunctions(firebase);
