import React from 'react';
import { styled, alpha } from '@mui/material/styles'
import { Grid, Paper, Typography, Switch } from "@mui/material";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { setupFunctionsEmulator } from "../shows/utils";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { green, red } from '@mui/material/colors';

setupFunctionsEmulator(functions);

const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: green[600],
        "&:hover": {
            backgroundColor: alpha(
                green[600],
                theme.palette.action.hoverOpacity
            ),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: green[600],
    },
    "& .MuiSwitch-switchBase": {
        color: red[600],
        "&:hover": {
            backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-track": {
        backgroundColor: red[600],
    },
}));

export default function UserTable({ setMessage, setSeverity, setSnackbarOpen }) {
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        
        async function getEm() {
            const getUserResults = httpsCallable(functions, "getDoggers");
            return await getUserResults();
        }
    
        async function fetchUsers() {
            if (!users.length) {
                try {
                    const usrs = await getEm();
                    setUsers(usrs.data.users)
                } catch (error) {
                    console.error('Failed to fetch users:', error);
                }
            }
        }
    
        fetchUsers();
    }, [users]);

    const handleSwitchChange = (user, claimType) => async (event) => {
        let status = event.target.checked;
        const uid = user.uid;
        const message = `'${user.displayName}' accounts '${claimType}' property was set to '${status}'`;
        let severity = "success";
        switch(claimType) {
            
            case "approved":
                const approve = httpsCallable(functions, "vetApproval");
                const approveResult = await approve({claim: {approved: status}, uid});
                if ( !approveResult.data.success ) severity = "error";
            break;
            
            case "admin":
                const admin = httpsCallable(functions, "vetAdmin");
                const adminResult = await admin({claim: {admin: status}, uid});
                if ( !adminResult.data.success ) severity = "error";
            break;

            case "disabled":
                const enablement = httpsCallable(functions, "vetEnablement");
                const enablementResult = await enablement({status, uid});
                if ( !enablementResult.data.success ) severity = "error";
            break;

            default:
                console.log("Default");
        }

        setMessage(message);
        setSeverity(severity);
        setSnackbarOpen(true);

        // Update the user table
        const newUsers = users.map(usr => {
            if ( usr.id === user.id ) {
                if ( claimType === "disabled" ) user.disabled = status;
                if ( claimType === "approved" ) user.customClaims.approved = status;
                if ( claimType === "admin" ) user.customClaims.admin = status;
            }
            return usr;
        })
        setUsers(newUsers);
    };
    
    return (
        <Paper square={false} variant="outlined" sx={{ display: "flex", flexGrow: 1, bgcolor: "#3a3f43", p: 3,  mt: 3}}>    
            <Grid container>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                        User Table
                    </Typography>
                </Grid>
                <Grid container item justifyContent="center">
                    <TableContainer component={Box}>
                        <Table sx={{backgroundColor: "transparent"}} size="medium">
                            <TableHead>
                                <TableRow sx={{
                                    '&:hover': {
                                        backgroundColor: 'transparent', // Keeps the background color unchanged on hover
                                    },
                                }}>
                                    <TableCell sx={{color: "white"}}>Name</TableCell>
                                    <TableCell align="left" sx={{color: "white", '@media (max-width:1100px)': { display: 'none' }}}>UID</TableCell>
                                    <TableCell align="left" sx={{color: "white", '@media (max-width:650px)': { display: 'none' }}}>Email</TableCell>
                                    <TableCell align="left" sx={{color: "white"}}>Approved</TableCell>
                                    <TableCell align="left" sx={{color: "white"}}>Admin</TableCell>
                                    <TableCell align="left" sx={{color: "white"}}>Disabled</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users && users.map((usr, idx) => {
                                        return(
                                            <TableRow key={idx} sx={{color: "white"}}>
                                                <TableCell style={{ padding: 0, width: 100, border: 0, color: "white", textAlign: "center" }}>
                                                    {usr.displayName || 'N/A'}
                                                </TableCell>
                                                <TableCell align="left" sx={{border: 0, color: "white", '@media (max-width:1100px)': { display: 'none' }}}>{usr.uid || 'N/A'}</TableCell>
                                                <TableCell align="left" sx={{border: 0, color: "white", '@media (max-width:650px)': { display: 'none' }}}>{usr.email || 'N/A'}</TableCell>
                                                <TableCell sx={{border: 0, color: "white", textAlign: "left"}}>
                                                    <CustomSwitch
                                                        checked={usr.customClaims?.approved || false}
                                                        onChange={handleSwitchChange(usr, 'approved')}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{border: 0, color: "white", textAlign: "left"}}>
                                                    <CustomSwitch
                                                        checked={usr.customClaims?.admin || false}
                                                        onChange={handleSwitchChange(usr, 'admin')}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{border: 0, color: "white", textAlign: "left"}}>
                                                    <CustomSwitch
                                                        checked={usr.disabled || false}
                                                        onChange={handleSwitchChange(usr, 'disabled')}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Paper>
    )
}
