import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { timestampToDate2 } from './utils';
import Setlist from './Setlist';
import { PlaybackProvider } from './PlaybackContext';

export default function ShowDetails({ data, setSelectedShow}) {
    const [showData, setShowData] = React.useState(null);

    React.useEffect(() => {
        if ( data ) {
            const { show, imageUrl } = data;
            setShowData({ ...show, imageUrl })
        }
    }, [data]);
    
    return (
        !showData ? null :
        <Grid container>
            {/* Back Button */}
            <Grid item xs={12} sx={{
                display: "flex",
                p: {xs: 1, sm: 0},
                mr: {xs: 0, sm: 2}
            }}>
                <IconButton onClick={() => setSelectedShow(null)} 
                    sx={{
                        alignItems: 'flex-start',
                        mr: 2
                    }}
                >
                    <ArrowBackIcon sx={{color: "white"}}/>
                </IconButton>
            </Grid>

            {/* Show Image */}
            <Grid item xs={12} md 
                sx={{
                    display: "flex",
                    flexGrow: 0,
                    pt: {
                        md: 3
                    },
                    pl: {
                        md: 3
                    },
                    justifyContent: {
                        xs: "center",
                        lg: "flex-start",
                    }
                }}
            >
                <Grid component="img" src={showData.imageUrl} alt='venue'
                    sx={{
                        maxWidth: {
                            xs: "325px",
                            sm: "375px"
                        }
                    }}
                />
            </Grid>
            
            {/* Show Details */}
            <Grid item flexDirection="column" 
                sx={{ 
                    display: "flex",
                    flexGrow: 1,
                    
                    pt: {
                        xs: 2,
                        md: 3
                    },
                    gap: 1,
                    alignItems: {
                        xs: "center",
                        md: "flex-start"
                    },
                    ml: {md: 4},
                }}
            >
                <Typography variant="h4" 
                    sx={{
                        fontSize: {
                            sm: 30,
                            md: 40
                        }
                    }}
                >
                    {showData.venue}
                </Typography>

                <Typography variant="body1"
                    sx={{
                        fontSize: {
                            sm: 25,
                        }
                    }}    
                >
                    {`${showData.city}, ${showData.state}`}
                </Typography>

                <Typography variant="body1"
                    sx={{
                        fontSize: {
                            sm: 25,
                        }
                    }}
                >
                    <strong>{timestampToDate2(showData.date)}</strong>
                </Typography>
            </Grid>

            {/* Setlist */}
            <PlaybackProvider>
                <Setlist showData={showData} />
            </PlaybackProvider>
        </Grid>
    )
}