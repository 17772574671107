import React from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { Grid, Paper, Typography, Box, Button } from '@mui/material';
import { Dropzone, FileMosaic } from '@files-ui/react';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from "../../../firebase";

export default function NewVideos() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({videos: []});
    const [touched, setTouched] = React.useState({videos: false});
    const [uploadProgress, setUploadProgress] = React.useState({});

    // Validate form fields
    const validate = () => {
        let temp = {};
        temp.videos = formData.videos.length ? '' : 'A \"video(s)\" is required.';
        return temp;
    };

    const errors = validate();

    // Hook used for redirecting logged out users to the signin page
    React.useEffect(() => {
        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);

    // Handles adding videos
    const handleAdd = (videos) => {
        // console.log(videos)
        setFormData({ ...formData, 'videos': videos });

        if(videos.length === 0) {
            setTouched({ ...touched, 'videos': true });
            return;
        }
        
        setTouched({ ...touched, 'videos': false });
    };

    // Handles removing videos
    const handleRemove = (fileId) => {
        // console.log(fileId)
        const vids = formData.videos.filter((x) => x.id !== fileId);
        setFormData({ videos: vids })
    }

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.videos.length) {
            console.log('Form data:', formData);

            for( const vid of formData.videos ) {
                console.log(vid);

                // Create the reference to the storage location
                const storageRef = ref(storage, `videos/${vid.name}`);

                // Upload each file to the cloud storage ref
                const uploadTask = uploadBytesResumable(storageRef, vid.file);

                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload '${vid.name}' is ${progress}% done`);
                
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [vid.id]: progress
                    }));
                
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log(`Upload '${vid.name}' is running`);
                            break;
                    }
                }, error => {
                    console.log("ERROR : ", error);
                }, () => {
                    console.log("Finished : ", uploadTask.snapshot.ref);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [vid.id]: 100 // Assume 100% once completed
                    }));
                });
            }
        }

        setTouched({ videos: true });
    };

    return (
        <Grid container sx={{pt: 3, pb: 5}} justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", p: {xs: 1, sm: 3}}}>
                    <Typography variant="h5" sx={{m: 2}}>
                        Add A Video
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Grid container item justifyContent="center" style={{paddingTop: "15px"}}>
                            <Grid item xs={10}>
                                <Typography variant="body1" sx={{
                                    m: 1, 
                                    fontSize: "14pt",
                                    paddingLeft: {
                                        md: "100px"
                                    }
                                }}>
                                    Drop any videos to add in the dropzone below.
                                </Typography>
                            </Grid>

                            {/* Dropzone */}
                            <Grid item xs={8}>
                                <Dropzone
                                    onChange={handleAdd}
                                    value={formData.videos}
                                    accept="video/*"
                                    color='white'
                                    background='#424242'
                                    label='Video Dropzone'
                                    footer={false}
                                    autoClean
                                >
                                    {
                                        formData.videos.map((file) => (
                                            <FileMosaic 
                                                key={file.id} 
                                                {...file} 
                                                onDelete={handleRemove}
                                                style={{color: "white"}}
                                                alwaysActive={false}
                                                smartImgFit="center"
                                                uploadStatus={uploadProgress[file.id] >= 100 ? 'success' : 'uploading'}
                                                progress={uploadProgress[file.id] || 0}
                                            />
                                        ))
                                    }
                                </Dropzone>
                            </Grid>

                            {/* Help Text */}
                            <Grid item xs={8} sx={{pl: 3, pt: 1}}>
                                {
                                    !touched.videos ? null :
                                    <Typography variant='subtitle2' sx={{color: '#d2302f'}}>{errors.videos}</Typography>
                                }
                            </Grid>
                        </Grid>

                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{ 
                                    mt: 5, 
                                    mb: 3,
                                    backgroundColor: theme => theme.palette.primary.main, // Default background color
                                    '&:disabled': {
                                        backgroundColor: '#6E6E6E' // Gray color for disabled state
                                    }
                                }}
                                disabled={!!errors.videos}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
