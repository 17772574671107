import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Avatar, Menu, MenuItem } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
// import { httpsCallable } from "firebase/functions";
import { auth } from "../../firebase";
import { functions } from "../../firebase";
import { setupFunctionsEmulator } from "../shows/utils";

setupFunctionsEmulator(functions);

export default function SignedInLinks() {
    const userCtx = React.useContext(UserContext);
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Function used to handle signing the user out of the Google provider
    const signOut = () => {
        auth.signOut().then(() => {
            setAnchorEl(null)
        })
    }

    /* const approve = async () => {
        console.log("approve ", userCtx.user)
        const approve = httpsCallable(functions, "vetApproval");
        const approveResult = await approve({claim: {approved: true}, uid: userCtx.user.user_id});
        console.log(approveResult.data);
    }

    const unapprove = async () => {
        console.log("unapprove ", userCtx.user)
        const approve = httpsCallable(functions, "vetApproval");
        const approveResult = await approve({claim: {approved: false}, uid: userCtx.user.user_id});
        console.log(approveResult.data);
    } */

    return(
        <Box>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} style={{color: "white"}}>
                <Avatar alt={userCtx.user.displayName} src={userCtx.user.picture}/>
            </IconButton>
            <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: "top",horizontal: "right"}}
                keepMounted
                transformOrigin={{vertical: "top",horizontal: "right"}}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {/* {!userCtx.user.admin && (
                    <MenuItem onClick={approve}>Approve</MenuItem>
                )} */}

                {/* {userCtx.user.admin && (
                    <MenuItem onClick={unapprove}>Un-Approve</MenuItem>
                )} */}

                {   // Admin Menu Items
                    !userCtx.user.admin ? null :
                        [{desc: "New Show", path: "/newShow"}, 
                        {desc: "New Video", path: "/newVideo"}, 
                        {desc: "New Picture", path: "/newPicture"}, 
                        {desc: "Admin", path: "/admin"}]
                        .map((itm, idx) => (
                            <MenuItem key={idx} onClick={() => {
                                setAnchorEl(null);
                                navigate(itm.path);
                            }}>
                                {itm.desc}
                            </MenuItem>
                        ))
                }

                <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
        </Box>
    );
}
