import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { setupAuthEmulator } from "../components/shows/utils";

const UserContext = createContext();

// Sets up the authenticator
setupAuthEmulator(auth);

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    // Hook used to update the user context used throughout the app
    useEffect(() => {
        // Update the user on authentication event changes
        onAuthStateChanged(auth, (user) => {
            // Handle logged out user
            if(!user) setUser(user);
            // Handle setting the user when successfully logged in
            if(user) {
                user.getIdTokenResult().then(idTokenResult => {
                    // console.log(idTokenResult.claims)
                    setUser(idTokenResult.claims);
                })
            }
        });
    },[]);

    // Memoize the full user context values
    const userContextValue = React.useMemo(() => ({
        user
    }), [user]);

    return (
        <UserContext.Provider value={userContextValue}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider }