import React from 'react';
import { Grid, Paper, Typography } from "@mui/material";

export default function PrivacyPolicy() {
    return (
        <Grid container sx={{pt: 3}} justifyContent="center">
            <Grid item xs={11} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p: {xs: 2, sm: 3}, color: "white"}}>
                    <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography gutterBottom variant="h5">
                                Privacy Policy for The Bomb Range
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                Effective Date : March 1st 2024
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                PMH Productions (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates The Bomb Range 
                                website (hereinafter referred to as the &quot;Service&quot;).
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                This page informs you of our policies regarding the collection, use, and disclosure of personal 
                                data when you use our Service and the choices you have associated with that data. <strong>We do 
                                not collect any personal information about the users of our Service.</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Information Collection And Use
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                We do not collect any personal data about you when you use our Service. This means that you can 
                                use our Service without the concern of your personal data being collected.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Tracking & Cookies Data
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                We do not use cookies or similar tracking technologies to monitor our Service.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Use of Data
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                Since we do not collect any personal data, there is no data to use or disclose.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Security of Data
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                The security of your data is important to us, but remember that no method of transmission 
                                over the Internet or method of electronic storage is 100% secure. While we strive to use
                                commercially acceptable means to protect your Personal Information, we cannot guarantee 
                                its absolute security as we do not collect personal information.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Changes to This Privacy Policy
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                We may update our Privacy Policy from time to time. We will notify you of any changes by 
                                posting the new Privacy Policy on this page. You are advised to review this Privacy 
                                Policy periodically for any changes. Changes to this Privacy Policy are effective when 
                                they are posted on this page.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="h5" gutterBottom>
                                Contact Us
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                If you have any questions about this Privacy Policy, please contact us.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom: 20}}>
                            <Typography variant="body1" gutterBottom>
                                <a style={{color: "white"}} href="mailto:admin@bombrange.com">PMH Productions</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}