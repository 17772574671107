import React from 'react';
import { Box, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, CircularProgress } from '@mui/material';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import { storage } from '../../firebase';
import { setupStorageEmulator, parseShowData } from './utils';
import PlaybackDrawer from './PlaybackDrawer';
import { usePlayback } from './PlaybackContext';

// Used for handling which firebase to use Cloud or Emulator
setupStorageEmulator(storage);

// The Set table row
const SetRow = ({ set }) => (
    <TableRow>
        <TableCell align="left" sx={{border: 0}}>
            <Typography variant="h6">{set.title}</Typography>
        </TableCell>
    </TableRow>
);

// The Song table row
const SongRow = ({ song, handleRowSelected }) => {
    const { isTrackPlaying, currentSong } = usePlayback();

    const isSelected = currentSong?.track === song.track;
    
    return (
        <TableRow hover sx={{color: "black"}} selected={song.selected} onClick={handleRowSelected(song)}>
            <TableCell align="center" style={{ padding: 0, width: 100, border: 0, color: "white" }}>
                {
                    isSelected ?
                    (isTrackPlaying ?
                        <IconButton sx={{ color: "white" }}>
                            <PauseCircleOutline />
                        </IconButton>
                        :
                        <IconButton sx={{ color: "white" }}>
                            <PlayCircleOutline />
                        </IconButton>
                    )
                    :
                    song.track
                }
            </TableCell>
            <TableCell align="left" sx={{border: 0, color: "white"}}>{song.song}</TableCell>
            <TableCell align="center" sx={{border: 0, color: "white"}}>
                { song.duration }
            </TableCell>
        </TableRow>
    )
};

// Simple circular loader
const Loading = () => (
    <Box sx={{ display: 'inline-flex', position: 'relative', flexGrow: 1, justifyContent: "center" }}>
        <CircularProgress size={80}/>
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="caption" component="div" sx={{color: "white"}}>Loading...</Typography>
        </Box>
    </Box>
);

export default function Setlist({ showData }) {
    const { currentSong, setCurrentSong, onPlayPause, songlist, setSonglist } = usePlayback();
    const [loaded, setLoaded] = React.useState(false);
    const [isPlayerOpen, setIsPlayerOpen] = React.useState(false);

    // Hook used to parse the incoming showdata
    React.useEffect(() => {
        if ( !songlist.length ) {
            parseShowData(showData, storage, setSonglist, setCurrentSong)
            .then(() => {
                setLoaded(true);
                setIsPlayerOpen(true);
            })
            .catch(console.error);
        }
    }, [showData, songlist.length]);

    // When 
    React.useEffect(() => {
        if(currentSong) {
            onPlayPause();
        } 
    }, [currentSong])

    // Function used to toggle the WaveSurfer drawer to open up from bottom
    const handleRowSelected = (song) => () => {
        // Play/Pause the song if the row is selected
        if( song === currentSong ) {
            onPlayPause();
            return;
        }
        setCurrentSong(song);
    };

    // Makes more bottom margin on the table with the player is open
    const playerOpen = isPlayerOpen ? { marginBottom: { xs: 21, sm: 13  }} : {};

    return (
        <Grid container item xs={12} sx={{margin: {xs: 0, sm: 1, md: 3}, pt: 2}}>
            {/* Playback */}
            <PlaybackDrawer isPlayerOpen={isPlayerOpen}/>
            
            {   // Song List Table
                !loaded ? <Loading /> :
                <TableContainer component={Box}>
                    <Table 
                        sx={{
                            backgroundColor: "black", 
                            ...playerOpen,
                            "& .MuiTableRow-root:hover": {
                                backgroundColor: "transparent !important"
                            }
                        }} 
                        size="medium"
                    >
                        {
                            songlist.map(item => {
                                return item.type === 'set' ?
                                        <TableHead key={item.title}>
                                            <SetRow set={item}/>
                                        </TableHead>
                                    :
                                        <TableBody key={item.track} sx={{height: "70px"}}>
                                            <SongRow song={item} handleRowSelected={handleRowSelected}/>
                                        </TableBody>
                            })
                        }
                    </Table>
                </TableContainer>
            }
        </Grid>
    )
}
