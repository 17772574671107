import React, { createContext, useContext, useRef, useState, useCallback } from 'react';

const PlaybackContext = createContext();
export const usePlayback = () => useContext(PlaybackContext);

export const PlaybackProvider = ({ children }) => {
    const audioRef = useRef(null);
    const [isTrackPlaying, setIsTrackPlaying] = useState(false);
    const [currentSong, setCurrentSong] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);
    const [songlist, setSonglist] = React.useState([]);

    const onPlayPause = useCallback(() => {
        if (audioRef.current.paused) {
            audioRef.current.play();
            setIsTrackPlaying(true);
        } else {
            audioRef.current.pause();
            setIsTrackPlaying(false);
        }
    }, []);

    const changeTrack = useCallback((trackChange) => {
        if( currentSong ) {
            const nextTrack = +currentSong.track + trackChange;
            const nextSong = songlist.find(song => song.track === nextTrack.toString());
            if( nextSong ) setCurrentSong(nextSong);
        }
    }, [currentSong]);

    const updateTime = (e, value) => {
        audioRef.current.currentTime = value;
    }

    const onTimeUpdate = (ref) => {
        setElapsedTime(ref.target.currentTime); 
        setRemainingTime(ref.target.duration - ref.target.currentTime);
    };

    return (
        <PlaybackContext.Provider value={{
            audioRef,
            isTrackPlaying,
            currentSong,
            setCurrentSong,
            onPlayPause,
            changeTrack,
            elapsedTime,
            remainingTime,
            songlist, 
            setSonglist,
            updateTime
        }}>
            {children}
            {currentSong && (
                <audio
                    ref={audioRef}
                    src={currentSong.downloadURL}
                    onEnded={() => changeTrack(1)}
                    onTimeUpdate={onTimeUpdate}
                />
            )}
        </PlaybackContext.Provider>
    );
};