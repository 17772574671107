import React from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Grid, Paper, Typography } from "@mui/material";
import SnackBar from '../snackbar/SnackBar';
import EmailTest from './EmailTest';
// import ApprovalTest from './ApprovalTest';
import UserTable from './UserTable';

export default function Admin() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("info");

    React.useEffect(() => {
        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);
    
    return (
        userCtx.user && !userCtx.user.admin ? navigate("/") :
        <>
        <Grid container sx={{pt: 3}} justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p: {xs: 2, sm: 3}, color: "white"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6">
                                Admin Dashboard
                            </Typography>
                        </Grid>

                        <EmailTest setMessage={setMessage} setSeverity={setSeverity} setSnackbarOpen={setSnackbarOpen}/>

                        {/* <ApprovalTest setMessage={setMessage} setSeverity={setSeverity} setSnackbarOpen={setSnackbarOpen}/> */}

                        <UserTable setMessage={setMessage} setSeverity={setSeverity} setSnackbarOpen={setSnackbarOpen} />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

        {/* SnackBar */}
        <SnackBar
            open={snackbarOpen}
            handleClose={(event, reason) => {
                if (reason === "clickaway") return;
                setSnackbarOpen(false);
            }}
            message={message}
            severity={severity}
        />
        </>
    )
}
