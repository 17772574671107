import React from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { Grid, Paper, Typography, Box, Button } from '@mui/material';
import AudioDropzone from './AudioDropzone';
import ShowInfo from './ShowInfo';
import ImageDropzone from './ImageDropzone';
import { doc, collection, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from "../../../firebase";

const formDefault = {
    venue: '',
    city: '',
    state: '',
    date: '',
    files: [],
    image: []
}

export default function NewShow() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState(formDefault);
    const [touched, setTouched] = React.useState({
        venue: false,
        city: false,
        state: false,
        date: false,
        files: false,
        image: false
    });
    const [uploadProgress, setUploadProgress] = React.useState({});

    // Validate form fields
    const validate = () => {
        let temp = {};
        temp.venue = formData.venue ? '' : 'The \"venue\" field is required.';
        temp.city = formData.city ? '' : 'The \"city\" field is required.';
        temp.state = formData.state ? '' : 'The \"state\" field is required.';
        temp.date = formData.date ? '' : 'The \"date\" field is required.';
        temp.files = formData.files.length ? '' : 'MP3 \"files\" are required.';
        temp.image = formData.image.length ? '' : 'A show \"image\" pic is required.';
        return temp;
    };

    const errors = validate();

    // Hook used for redirecting logged out users to the signin page
    React.useEffect(() => {
        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);
    
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.venue && formData.city && formData.state && formData.date && formData.files.length && formData.image.length) {
            // console.log('Form data:', formData);

            const songs = formData.files.filter(f => f.type === 'song');
            const setsData = songs.reduce((acc, song) => {
                // Initialize the set object if it hasn't been created yet
                if (!acc[song.set]) {
                    let setName = `Set ${song.set}`;
                    if( song.set === "E" || song.set === "e" ) setName = "Encore"
                    acc[song.set] = {
                        title: setName,
                        songs: [],
                    };
                }

                // Add the song to the set
                acc[song.set].songs.push({ title: song.data.name, duration: song.duration });
                return acc;
            }, {});

            // Convert the setsData object into an array of set values
            const sets = Object.values(setsData);

            // Create the show object
            let show = {
                venue: formData.venue,
                city: formData.city,
                state: formData.state,
                date: new Date(formData.date),
                showPath: `${formData.date}_${formData.city.toLocaleLowerCase()}_${formData.state.toLocaleLowerCase()}`,
                sets
            }
            console.log(show)

            // Update Storage
            const uploadFiles = formData.files.filter(t => t.type === 'song').map(s => (s.data));

            // Add the show image file to the upload files.
            uploadFiles.push(formData.image[0])

            uploadFiles.forEach(async (f) => {
                // console.log("Uploading  : ", f.name);

                // Create the reference to the storage location
                const storageRef = ref(storage, `shows/${show.showPath}/${f.name}`);

                // Upload each file to the cloud storage ref
                const uploadTask = uploadBytesResumable(storageRef, f.file);

                // Handle displaying progress when uploading files
                uploadTask.on('state_changed', (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [f.id]: progress
                    }));
                }, error => {
                    console.log("ERROR : ", error);
                }, () => {
                    // console.log("Finished uploading : ", f.name);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [f.id]: 100 // Assume 100% once completed
                    }));
                });
            })

            // Update Firestore
            // Create a reference to the shows collection
            const cRef = collection(db, `shows`);
            // Add a new document to the shows collection with the show data
            await setDoc(doc(db, 'shows', show.showPath), show);
        }
        setTouched({ venue: true, city: true, state: true, date: true, files: true, image: true });
    };
    
    return (
        <Grid container sx={{pt: 3, pb: 5}} justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", p: {xs: 1, sm: 3}}}>
                    <Typography variant="h5" sx={{m: 2}}>
                        Add A New Show
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        sx={{ 
                            '& .MuiTextField-root': { 
                                my: 1,
                            },
                            '& .MuiInputBase-input': {
                                color: 'white', // Change text color here
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'white', // Change border color here
                                },
                                '&:hover fieldset': {
                                    borderColor: 'white', // Change border color on hover here
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'blue', // Change border color when focused here
                                },
                            }
                        }}
                    >
                        <ShowInfo 
                            formData={formData} 
                            setFormData={setFormData} 
                            touched={touched} 
                            setTouched={setTouched}
                            errors={errors}
                        /> 

                        <ImageDropzone 
                            formData={formData} 
                            setFormData={setFormData} 
                            touched={touched} 
                            setTouched={setTouched}
                            errors={errors}
                            uploadProgress={uploadProgress}
                        />
                        
                        <AudioDropzone 
                            formData={formData} 
                            setFormData={setFormData} 
                            touched={touched} 
                            setTouched={setTouched}
                            errors={errors}
                            uploadProgress={uploadProgress}
                        />
                        
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{ 
                                    mt: 5,
                                    mb: 3,
                                    backgroundColor: theme => theme.palette.primary.main, // Default background color
                                    '&:disabled': {
                                        backgroundColor: '#6E6E6E' // Gray color for disabled state
                                    } 
                                }}
                                disabled={!!(errors.venue || errors.city || errors.state || errors.date || errors.files.length || errors.image.length)}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}