import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Container, Toolbar, Typography, IconButton, Menu, MenuItem, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import bombrangeLogo from "../../assets/BombRangeLogo.png";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import SnackBar from "../snackbar/SnackBar";

const pages = ['Shows', 'Pictures', 'Videos'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const BombRangeLogo = (props) => (
    <a href="https://bombrange.com" target="_blank" rel="noreferrer">
        <Box component="img" src={bombrangeLogo} alt="ETC Logo" sx={{ height: "50px", ...props.sx }} />
    </a>
)

export default function NavBar() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("info");

    return (
        <>
        <AppBar position="static" color="transparent" elevation={0}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* Mobile */}
                    <BombRangeLogo sx={{ display: { xs: "none", md: "flex" }, mr: 3 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        onClick={() => navigate("/")}
                        component="a"
                        sx={{
                            mr: 2,
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 700,
                            fontSize: { sm: "1.7rem" },
                        }}
                    >
                        The Bomb Range
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" },}}>
                    {
                        !userCtx.user ? null :
                            !userCtx.user.approved ? null :
                            <>
                            <IconButton size="large" onClick={(e) => setAnchorElNav(e.currentTarget)} color="primary">
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left",}}
                                keepMounted
                                transformOrigin={{ vertical: "top", horizontal: "left",}}
                                open={Boolean(anchorElNav)}
                                onClose={() => setAnchorElNav(null)}
                                sx={{ display: { xs: "block", md: "none" }, }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={() => setAnchorElNav(null)}>
                                        <Typography 
                                            variant="button" 
                                            onClick={() => navigate("/" + page.toLowerCase())}
                                            component="a"
                                            textAlign="center" 
                                            sx={{ textDecoration: 'none'}}
                                        >
                                            {page}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                            </>
                    }
                    </Box>

                    {/* Desktop */}
                    <BombRangeLogo sx={{ display: { xs: "flex", md: "none" }, mr: 2 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        onClick={() => navigate("/")}
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontWeight: 700,
                            fontSize: { xs: "1.1rem", sm: "1.5rem" }
                        }}
                    >
                        The Bomb Range
                    </Typography>
                        <Box sx={{ flexGrow: 0, mr: 2, display: { xs: "none", md: "flex" }, }}>
                            {
                                !userCtx.user ? null :
                                    !userCtx.user.approved ? null :
                                    (pages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => {
                                                navigate("/" + page.toLowerCase())
                                                setAnchorElNav(null)
                                            }}
                                            sx={{ mx: 1, my: 2, color: "white", display: "block", fontWeight: 700, fontSize: "12pt" }}
                                        >
                                            {page}
                                        </Button>
                                    )))
                            }
                        </Box>

                    {/* Avatar */}
                    <Box sx={{ flexGrow: 0, ml: 0 }}>
                        {
                            userCtx.user ? <SignedInLinks /> : 
                            <SignedOutLinks 
                                setMessage={setMessage} 
                                setSeverity={setSeverity} 
                                setSnackbarOpen={setSnackbarOpen}
                            />
                        }
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

        {/* SnackBar */}
        <SnackBar
            open={snackbarOpen}
            handleClose={(event, reason) => {
                if (reason === "clickaway") return;
                setSnackbarOpen(false);
            }}
            message={message}
            severity={severity}
        />
        </>
    );
}


