import React from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { Grid, Paper, Typography, Box, Button } from '@mui/material';

export default function NewPictures() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();

    // Hook used for redirecting logged out users to the signin page
    React.useEffect(() => {
        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]);
    
    return (
        <Grid container sx={{pt: 3, pb: 5}} justifyContent="center">
            <Grid item xs={12} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", p: {xs: 1, sm: 3}}}>
                    <Typography variant="h5" sx={{m: 2}}>
                        Add A Picture
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}