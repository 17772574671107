import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { grey } from '@mui/material/colors'

let theme = createTheme({
    palette: {
        primary: {
            main: "#F5F5F5",
            secondary: '#555'
        },
        secondary: {
            main: "#19857b",
        },
        background: {
            paper: grey[900]
        }
    },
    typography: {
        allVariants: {
            color: "#F5F5F5"
        }
    },
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    ":hover": {
                        backgroundColor: `${grey[800]} !important` 
                    }
                }
            }
        }
    }
});

theme = responsiveFontSizes(theme);

export default theme;