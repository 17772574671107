import React from 'react';
import { Grid, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Dropzone, FileMosaic } from '@files-ui/react';
import { convertDuration } from '../utils';

// The Set table row
const SetRow = ({ set }) => (
    <TableRow>
        <TableCell align="left" sx={{border: 0}}>
            <Typography variant="h6">{set.title}</Typography>
        </TableCell>
    </TableRow>
);

// The Song table row
const SongRow = ({ song }) => {
    const [duration, setDuration] = React.useState();
    
    // Hook used to set the track duration information
    React.useEffect(() => {
        song.duration = duration;;
    }, [duration])
    
    return (
        <TableRow sx={{color: "black"}}>
            <TableCell align="center" style={{ padding: 0, width: 100, border: 0, color: "white" }}>
                {song.track}
            </TableCell>
            <TableCell align="left" sx={{border: 0, color: "white"}}>
                {song.song}
            </TableCell>
            <TableCell align="center" sx={{border: 0, color: "white"}}>
                <audio 
                    id={`track-${song.track}`}
                    src={URL.createObjectURL(song.data.file)}
                    onCanPlayThrough={(evt) => setDuration(convertDuration(evt.currentTarget.duration))} 
                    preload="metadata"
                />
                    {duration}
            </TableCell>
        </TableRow>
    )
};

export default function AudioDropzone({formData, setFormData, touched, setTouched, errors, uploadProgress}) {
    const [files, setFiles] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    
    const updateFiles = (newFiles) => {
        // console.log("Updated Files : ", newFiles)
        
        if(newFiles.length === 0) {
            setFiles([]);
            setFormData({ ...formData, 'files': [] });
            setTouched({ ...touched, 'files': true });
            return;
        }
        
        const updatedFiles = [...files];
        newFiles.forEach((file) => {
            if (!files.some((f) => f.name === file.name)) {
                updatedFiles.push(file);
            } else {
                console.log(`Duplicate file detected: ${file.name}`);
            }
        });
        setFiles(updatedFiles);
    };

    React.useEffect(() => {
        // File schema regexp
        const pattern = /^(\d+|[Ee])\-(\d+)\s(.*)\.(mp3|m4a)$/;
        
        // Need this object array to sort the sets lists properly
        let setData = {};
        for(const f of files) {
            // Perform the regex match
            const match = pattern.exec(f.name);

            // For each set create a blank array in the setlist object
            if(!setData.hasOwnProperty(match[1])) setData[match[1]] = [];

            // Push the set song into the setlist array
            setData[match[1]].push(match[0]);

            // Sort the Set song array.
            setData[match[1]].sort();
        }
        // console.log(setData)

        // Then this loop builds the table data from the sorted setData data
        let tableSetlistData = [];
        for(const set in setData) {
            let setName = `Set ${set}`;
            if( set === "E" || set === "e" ) setName = "Encore"
            tableSetlistData.push({title: setName, type: 'set'});
            for(const song of setData[set]){
                const match = pattern.exec(song);
                const data = files.find(f => f.name === song);
                tableSetlistData.push({
                    set: match[1],
                    track: match[2].replace(/^0+/, ""),
                    song: match[3],
                    type: 'song',
                    data
                })
            }
        }
        // console.log(tableSetlistData)
        setTableData(tableSetlistData);
        setFormData({ ...formData, 'files': tableSetlistData });
        setTouched({ ...touched, 'files': false });
    }, [files]);

    // Function that handles updating the upload status of each file
    const getUploadProgress = (id) => {
        if ( uploadProgress[id] === 100 ) {
            return 'success';
        } else if( uploadProgress[id] ) {
            return 'uploading';
        }
    }
    
    return (
        <Grid container item justifyContent="center" style={{paddingTop: "15px"}}>
            <Grid item xs={10}>
                <Typography variant="body1" sx={{
                    m: 1, 
                    fontSize: "14pt",
                    paddingLeft: {
                        md: "100px"
                    }
                }}>
                    <strong>Step 3:</strong> Add all of the show MP3s to the dropzone.
                </Typography>
            </Grid>

            <Grid item xs={10} sx={{pb: 2, paddingLeft: {md: "150px"}}}>
                <Typography variant='body1'>
                    <strong>File name schema (Example) &quot;1-01 Banter.mp3&quot;</strong>
                </Typography>
                <Typography variant='body1' sx={{pl: 3}}>
                    <strong>First digit (1) :</strong> Set number (Can be any number between 1-9 or E (Encore))
                </Typography>
                <Typography variant='body1' sx={{pl: 3}}>
                    <strong>Second digit (01) :</strong> Track number (Can be any number between 1-99)
                </Typography>
                <Typography variant='body1' sx={{pl: 3}}>
                    <strong>Song Name :</strong> The name of the song (!!Don&apos;t forget the space between the track number and the song name!!)
                </Typography>
            </Grid>

            {/* Setlist */}
            {
                !tableData.length ? null :
                <Grid container item xs={8} justifyContent="center">
                    <Grid container item xs={12} sx={{m: 2}} justifyContent="center">
                        <Typography variant="body1" sx={{m: .5, fontSize: "20pt"}}>Setlist</Typography>
                    </Grid>
                    <Grid container item xs={8} sx={{pb: 5}}>
                        <TableContainer component={Paper}>
                            <Table sx={{backgroundColor: "black"}} size="small">
                                {
                                    tableData.map(item => {
                                        return item.type === 'set' ?
                                            <TableHead key={item.title}>
                                                <SetRow set={item}/>
                                            </TableHead>
                                        :
                                            <TableBody key={`${item.set}_${item.track}`} sx={{height: "50px"}}>
                                                <SongRow song={item}/>
                                            </TableBody>
                                    })  
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
            
            {/* Dropzone */}
            <Grid item xs={8}>
                <Dropzone
                    onChange={updateFiles}
                    value={files}
                    accept="audio/mpeg, audio/x-m4a"
                    color='white'
                    background='#424242'
                    label='Drop the shows MP3s here'
                    footer={false}
                    autoClean
                >
                    {
                        files.map((file) => (
                            <FileMosaic 
                                key={file.id} 
                                {...file} 
                                onDelete={() => setFiles(files.filter((x) => x.id !== file.id))} 
                                info 
                                style={{color: "white"}}
                                alwaysActive={false}
                                uploadStatus={getUploadProgress(file.id)}
                                progress={uploadProgress[file.id] || 0}
                            />
                        ))
                    }
                </Dropzone>
            </Grid>
            <Grid item xs={8} sx={{pl: 3, pt: 1}}>
                {
                    !touched.files ? null :
                    <Typography variant='subtitle2' sx={{color: '#d2302f'}}>
                        {errors.files}
                    </Typography>
                }
            </Grid>
        </Grid>
    )
}
